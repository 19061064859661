import { app } from './firebase';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, signOut  } from "firebase/auth";
import { getFirestore, limitQuery, collection, getDocs, addDoc, query, where, deleteDoc, orderBy, limit, doc, getDoc, runTransaction, updateDoc, Timestamp, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const db = getFirestore(app);
const auth = getAuth(app);

const sumarHoras = (fecha, horas) => {
  const fechaNueva = new Date(fecha); // Copia la fecha original
  fechaNueva.setHours(fechaNueva.getHours() + horas); // Suma las horas
  return fechaNueva;
};

const obtenerFechaHoraAjustada = (offset, horasAdicionales) => {
  // Obtener la fecha y hora actual en UTC
  const now = new Date();
  
  // Ajustar la fecha y hora a la zona horaria deseada
  const utcOffset = now.getTimezoneOffset() / 60; // Obtener el desfase de la zona horaria en horas
  const localOffset = offset - utcOffset; // Calcular el desfase para el ajuste
  const adjustedDate = new Date(now.getTime() + localOffset * 60 * 60 * 1000); // Ajustar la fecha
  
  // Sumar las horas adicionales
  adjustedDate.setHours(adjustedDate.getHours() + horasAdicionales);

  // Convertir la fecha ajustada a formato ISO
  return adjustedDate.toISOString();
};

const ajustarFechaHora = (date, offsetHours) => {
  const localDate = new Date(date);
  localDate.setHours(localDate.getHours() + offsetHours);
  return localDate;
};

// Función para formatear la fecha y hora en el formato ISO 8601
const formatearFechaHora = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
};

// Función para obtener la fecha y hora ajustadas en formato ISO 8601
const obtenerFechaHoraActual = (offsetHours = 0) => {
  // Obtener la fecha y hora actual en UTC
  const fechaUTC = new Date();
  
  // Ajustar la fecha y hora a la zona horaria deseada (si es necesario)
  const fechaLocal = ajustarFechaHora(fechaUTC, offsetHours);
  
  // Formatear la fecha y hora en formato ISO 8601
  return formatearFechaHora(fechaLocal);
};

// Función para almacenar datos en la caché
function cacheData(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

// Función para obtener datos del caché
function getCachedData(key) {
  const cachedData = localStorage.getItem(key);
  return cachedData ? JSON.parse(cachedData) : null;
}

// Función para eliminar datos del caché (opcional, si se necesita)
function clearCache(key) {
  localStorage.removeItem(key);
}

const firestorePasajeros = async(nombre, nro_celular, localidad, correo, idInicio) =>{
  try {
      const docRef = await addDoc(collection(db, "pasajeros"), {
          nombre: nombre,
          telefono: nro_celular,
          correo: correo,
          idInicio: idInicio,
          localidad: localidad
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
}

const registrarUsuario = async (datosRegistro) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, datosRegistro.correo, datosRegistro.contra);
    const user = userCredential.user;
    let nro_celular = `${datosRegistro.caract_cel}-${datosRegistro.nro_cel}`;

    await firestorePasajeros(datosRegistro.apynom, nro_celular, datosRegistro.localidad, datosRegistro.correo, user.uid);

    return { success: true, message: 'Usuario registrado con éxito' };
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      return { success: false, error: 'El correo electrónico ya está registrado' };
    } else {
      return { success: false, error: error.message };
    }
  }
};

const iniciarSesion = async (datosRegistro) => {
  try {
    document.cookie = "adminID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    await setPersistence(auth, browserSessionPersistence);

    const authResult = await signInWithEmailAndPassword(auth, datosRegistro.correo, datosRegistro.contra);

    if (authResult.user) {
      const pasajerosCollection = collection(db, 'pasajeros');
      const querySnapshot = await getDocs(query(pasajerosCollection, where('idInicio', '==', authResult.user.uid)));

      if (!querySnapshot.empty) {
        const usuarioDoc = querySnapshot.docs[0];
        const usuarioData = usuarioDoc.data();

        return {
          success: true,
          message: 'Inicio de sesión exitoso',
          usuario: usuarioData,
        };
      } else {
        return { success: false, message: 'Usuario no encontrado en la colección "pasajeros"' };
      }
    } else {
      return { success: false, message: 'Inicio de sesión exitoso, pero no se pudo obtener el usuario.' };
    }
  } catch (error) {
    return { success: false, error: 'Correo o contraseña incorrecta.' };
  }
};

const cerrarSesion = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
    
    // Eliminar la cookie adminID
    document.cookie = "adminID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    return {
      success: true,
      message: 'Sesión cerrada exitosamente',
    };
  } catch (error) {
    return {
      success: false,
      error: 'Error al cerrar sesión',
    };
  }
};


const loginAdmin = async (datosRegistro) => {
  try {
    const {correo, contra} = datosRegistro;

    // Consulta la colección "admin" en Firestore para encontrar al administrador
    const adminQuery = query(
      collection(db, 'admin'),
      where('correo', '==', correo),
      where('contra', '==', contra)
    );

    const adminSnapshot = await getDocs(adminQuery);

    if (!adminSnapshot.empty) {
      // Encuentra un administrador con las credenciales correctas
      const adminDoc = adminSnapshot.docs[0];
      const adminData = adminDoc.data();

      return {
        success: true,
        message: 'Inicio de sesión exitoso',
        admin: {
          id: adminDoc.id,
          correo: adminData.correo,
        },
      };
    } else {
      // Si no se encuentra un administrador con esas credenciales
      return {
        success: false,
        error: 'Correo o contraseña incorrectos',
      };
    }
  } catch (error) {
    return {
      success: false,
      error: 'Error en el inicio de sesión',
    };
  }
};



const registrarChofer = async (datosRegistro) => {
  try {
    const {apynom, correo, localidad, caract_cel, nro_cel, marca_vehiculo, modelo_vehiculo, patente_vehiculo, color_vehiculo} = datosRegistro;

    let nro_celular = `${caract_cel}-${nro_cel}`;

    if (!apynom || !nro_celular || !localidad || !correo || !marca_vehiculo || !modelo_vehiculo || !patente_vehiculo || !color_vehiculo) {
      return { success: false, error: "Todos los campos deben estar completos: apynom, nro_celular, localidad, correoelectronico, marca_vehiculo, modelo_vehiculo, patente_vehiculo, color_vehiculo" };
    }

    // Verificar si el correo ya está registrado
    const correoRegistradoQuery = query(collection(db, 'choferes'), where('correo', '==', correo));
    const correoRegistradoSnapshot = await getDocs(correoRegistradoQuery);

    if (!correoRegistradoSnapshot.empty) {
      return { success: false, error: "El correo electrónico ya está registrado", error: 400 };
    }

    const docRef = await addDoc(collection(db, 'choferes'), {
      nombre: apynom,
      correoelectronico: correo,
      localidad: localidad,
      nro_celular: nro_celular,
      marca_vehiculo: marca_vehiculo,
      modelo_vehiculo: modelo_vehiculo,
      patente_vehiculo: patente_vehiculo,
      color_vehiculo: color_vehiculo,
      url_imagen_vehiculo: ''
    });

    const marcadorRef = doc(db, 'marcadores', 'marcador_chofer');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Chofer registrado con éxito", id: docRef.id };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getChoferes = async (nombre, localidad) => {
  const cacheKey = `choferes_${nombre}_${localidad}`;
  const marcadorKey = 'marcador_choferes'; // Clave del marcador de choferes

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha inicial para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      // Comparar la fecha de la última actualización en la base de datos con la caché
      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        return cachedData.choferesList;
      }
    }

    // Si la caché no es válida o no existe, obtener datos de la base de datos
    const choferesRef = collection(db, 'choferes');
    let choferesQuery = query(choferesRef);

    if (nombre !== '0') {
      choferesQuery = query(choferesRef, where('nombre', '==', nombre));
    }

    if (localidad !== '0') {
      choferesQuery = query(choferesRef, where('localidad', '==', localidad));
    }

    const choferesSnapshot = await getDocs(choferesQuery);
    const choferesList = [];

    choferesSnapshot.forEach((doc) => {
      const choferData = doc.data();
      const choferId = doc.id; // Obtén el ID del documento

      // Agrega el ID del chofer a los datos del chofer
      choferData.id = choferId;

      choferesList.push(choferData);
    });

    // Almacenar los choferes en caché
    const newCache = {
      choferesList,
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    // Actualizar el marcador de choferes para reflejar la última modificación
    const marcadorRef = doc(db, 'marcadores', marcadorKey);
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6); // Ajuste de hora
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Actualizar con la fecha y hora ajustada
    }, { merge: true });

    return choferesList;
  } catch (error) {
    return { success: false, error: error.message };
  }
};


const getChoferPorViaje = async (idViaje) => {
  try {
    const viajeRef = doc(db, 'viajes', idViaje);

    const viajeDoc = await getDoc(viajeRef);

    if (viajeDoc.exists()) {
      const viajeData = viajeDoc.data();
      const choferId = viajeData.id_chofer; // Asumiendo que el campo se llama 'id_chofer'

      if (choferId) {
        const choferRef = doc(db, 'choferes', choferId);

        const choferDoc = await getDoc(choferRef);

        if (choferDoc.exists()) {
          const choferData = choferDoc.data();

          // Agrega el ID del chofer a los datos del chofer
          choferData.id = choferDoc.id;

          return choferData;
        } else {
          return { success: false, message: 'Chofer no encontrado para este viaje.' };
        }
      } else {
        return { success: false, message: 'No se encontró información del chofer para este viaje.' };
      }
    } else {
      return { success: false, message: 'No se encontró el viaje con el ID proporcionado.' };
    }
  } catch (error) {
    console.error('Error al obtener el chofer por ID:', error);
    return { success: false, message: 'Error al obtener el chofer por ID' };
  }
};

const getChoferPorId = async (idChofer) => {
  try {
    const choferRef = doc(db, 'choferes', idChofer);

    const choferDoc = await getDoc(choferRef);

    if (choferDoc.exists()) {
      const choferData = choferDoc.data();
      choferData.id = choferDoc.id;
      
      return { success: true, data: choferData };
    } else {
      return { success: false, message: 'No se encontró el chofer con el ID proporcionado.' };
    }
  } catch (error) {
    console.error('Error al obtener el chofer por ID:', error);
    return { success: false, message: 'Error al obtener el chofer por ID' };
  }
};

const crearViaje = async (formData, formDataParada) => {
  try {

    console.log('entra a crear viaje');

    const { origen, destino, fecSalida, horaIda, horaVuelta, pasajeros_ida, pasajeros_vuelta, precio, chofer, realizado } = formData;

    if (!origen || !destino || !fecSalida || !horaIda || !horaVuelta || !pasajeros_ida || !pasajeros_vuelta || !precio || !chofer) {
      return { success: false, error: "Todos los campos deben estar completos: origen, destino, fecha, hora, pasajeros, precio y chofer" };
    }
    

    for (let i = 0; i < fecSalida.length; i++) {
      console.log('entra')
      const fechaString = fecSalida[i];

      // Parsear la fecha desde el formato dd/mm/yyyy
      const [day, month, year] = fechaString.split('/');
      const fechaDate = new Date(year, month - 1, day); // Date en JS usa índices de mes basados en 0

      // Normaliza la fecha a medianoche
      fechaDate.setHours(0, 0, 0, 0);

      // Convertir la fecha a un Timestamp de Firestore
      const fechaTimestamp = Timestamp.fromDate(fechaDate);

      console.log(`Fecha Timestamp: ${fechaTimestamp.toDate()}`);

      // Crear viaje de ida
      const docRefIda = await addDoc(collection(db, 'viajes'), {
        origen,
        destino,
        fecha: fechaTimestamp,
        hora: horaIda,
        asientos_disponibles: pasajeros_ida,
        precio,
        id_chofer: chofer,
        realizado
      });

      if (!docRefIda.id) {
        return { success: false, error: "No se pudo crear el viaje de ida para la fecha: " + fechaString };
      }

      // Crear paradas para el viaje de ida
      if (formDataParada && formDataParada.length > 0) {
        for (let j = 0; j < formDataParada.length; j++) {
          const parada = formDataParada[j];
          const { ciudad, precio } = parada;
          await addDoc(collection(db, 'paradas'), {
            viaje_id: docRefIda.id,
            ciudad,
            precio,
            orden: j // Orden de la parada
          });
        }
      }

      // Crear viaje de vuelta
      const docRefVuelta = await addDoc(collection(db, 'viajes'), {
        origen: destino,  // Intercambio de origen y destino para el viaje de vuelta
        destino: origen,
        fecha: fechaTimestamp,
        hora: horaVuelta,
        asientos_disponibles: pasajeros_vuelta,
        precio,
        id_chofer: chofer,
        realizado
      });

      if (!docRefVuelta.id) {
        return { success: false, error: "No se pudo crear el viaje de vuelta para la fecha: " + fechaString };
      }

      // Crear paradas para el viaje de vuelta en orden inverso
      if (formDataParada && formDataParada.length > 0) {
        for (let k = formDataParada.length - 1; k >= 0; k--) {
          const parada = formDataParada[k];
          const { ciudad, precio } = parada;
          await addDoc(collection(db, 'paradas'), {
            viaje_id: docRefVuelta.id,
            ciudad,
            precio,
            orden: formDataParada.length - 1 - k // Orden de la parada en orden inverso
          });
        }
      }
    }

    // Actualizar el marcador de viajes con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Viajes y paradas creados con éxito y marcador actualizado" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};


const obtenerUltimosViajes = async (numUltimos) => {
  const cacheKey = `ultimos_viajes_${numUltimos}`;
  const cachedData = getCachedData(cacheKey);

  // Obtener la fecha de actualización desde el marcador
  const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
  const marcadorSnapshot = await getDoc(marcadorRef);
  const marcadorData = marcadorSnapshot.data();

  try {
    if (marcadorData && marcadorData.updateTime) {
      const marcadorUpdateTime = new Date(marcadorData.updateTime);
      const marcadorUpdateTimeConHoras = sumarHoras(marcadorUpdateTime, 3);

      if (cachedData && cachedData.lastUpdated) {
        const cachedLastUpdate = new Date(cachedData.lastUpdated);

        console.log(cachedLastUpdate > marcadorUpdateTimeConHoras)

        // Verificar si la caché es válida
        if (cachedLastUpdate > marcadorUpdateTimeConHoras) {
          console.log("Retornando datos desde la caché. viajes.");
          return {
            success: true,
            viajes: cachedData.viajes,
          };
        }
      }
    }
  } catch (error) {
    console.error('Error al obtener el marcador:', error);
  }

  try {
    // Si la caché no es válida o no existe, obtener datos de la base de datos
    const viajesCollection = collection(db, 'viajes');
    
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const currentTimestamp = Timestamp.fromDate(currentDate);

    const viajesQuery = query(
      viajesCollection,
      where('realizado', '==', 'false'),
      where('fecha', '>=', currentTimestamp),
      orderBy('fecha', 'asc'),
      limit(numUltimos)
    );

    const viajesSnapshot = await getDocs(viajesQuery);
    let viajes = [];

    viajesSnapshot.forEach((doc) => {
      const data = doc.data();
      viajes.push({ id: doc.id, ...data });
    });

    let marcadorUpdateTimeConHoras = 0;

    if (marcadorData && marcadorData.updateTime) {
      const marcadorUpdateTime = new Date(marcadorData.updateTime);
      marcadorUpdateTimeConHoras = sumarHoras(marcadorUpdateTime, 3);
    }

    // Almacenar los datos en caché
    const newCache = {
      viajes,
      lastUpdated: marcadorUpdateTimeConHoras,
    };
    cacheData(cacheKey, newCache);

    return {
      success: true,
      viajes,
    };
  } catch (error) {
    console.error('Error al obtener los últimos viajes:', error);
    return {
      success: false,
      message: 'Error al obtener los últimos viajes',
    };
  }
};



const obtenerViajesOrigenDestino = async (origen, destino, numViajes) => {
  // Crear una clave de caché única basada en los parámetros
  const cacheKey = `viajes_${origen}_${destino}_${numViajes}`;
  const cachedData = getCachedData(cacheKey);

  // Obtener la fecha de actualización desde el marcador
  const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
  const marcadorSnapshot = await getDoc(marcadorRef);
  const marcadorData = marcadorSnapshot.data();

  try {
    if (marcadorData && marcadorData.updateTime) {
      const marcadorUpdateTime = new Date(marcadorData.updateTime);
      const marcadorUpdateTimeConHoras = sumarHoras(marcadorUpdateTime, 3);

      if (cachedData && cachedData.lastUpdated) {
        const cachedLastUpdate = new Date(cachedData.lastUpdated);

        // Verificar si la caché es válida
        if (cachedLastUpdate >= marcadorUpdateTimeConHoras) {
          console.log("Retornando datos desde la caché.");
          return {
            success: true,
            viajes: cachedData.viajes,
          };
        }
      }
    }
  } catch (error) {
    console.error('Error al obtener el marcador:', error);
  }

  try {
    const viajesCollection = collection(db, 'viajes');

    // Obtener la fecha actual y normalizarla a medianoche
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
    const currentTimestamp = Timestamp.fromDate(currentDate);

    // Construir la consulta inicial
    let queryRef = query(
      viajesCollection,
      where('realizado', '==', 'false'),
      where('fecha', '>=', currentTimestamp),
      orderBy('fecha', 'asc'),
      limit(numViajes)
    );

    // Aplicar filtros según el origen y destino
    if (origen !== '0') {
      queryRef = query(queryRef, where('origen', '==', origen));
    }

    if (destino !== '0') {
      queryRef = query(queryRef, where('destino', '==', destino));
    }

    const viajesSnapshot = await getDocs(queryRef);
    let viajes = [];

    viajesSnapshot.forEach((doc) => {
      const data = doc.data();
      viajes.push({ id: doc.id, ...data });
    });

    // Almacenar la fecha de actualización
    let marcadorUpdateTimeConHoras = 0;

    if (marcadorData && marcadorData.updateTime) {
      const marcadorUpdateTime = new Date(marcadorData.updateTime);
      marcadorUpdateTimeConHoras = sumarHoras(marcadorUpdateTime, 3);
    }

    // Guardar los datos en caché
    const newCache = {
      viajes,
      lastUpdated: marcadorUpdateTimeConHoras,
    };
    cacheData(cacheKey, newCache);

    return {
      success: true,
      viajes,
    };
  } catch (error) {
    console.error('Error al obtener los viajes:', error);
    return {
      success: false,
      message: 'Error al obtener los viajes',
    };
  }
};

const getViajePorId = async (idDelViaje) => {
  const cacheKey = `viaje_${idDelViaje}`;
  const cachedData = getCachedData(cacheKey);

  try {
    // Obtener la fecha de actualización desde el marcador
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
    const marcadorSnapshot = await getDoc(marcadorRef);
    const marcadorData = marcadorSnapshot.data();

    let shouldFetchFromDb = false;

    if (marcadorData && marcadorData.updateTime) {
      const marcadorUpdateTime = new Date(marcadorData.updateTime);
      const marcadorUpdateTimeConHoras = sumarHoras(marcadorUpdateTime, 3);

      if (cachedData && cachedData.lastUpdated) {
        const cachedLastUpdate = new Date(cachedData.lastUpdated);

        // Verificar si la caché es válida
        if (cachedLastUpdate >= marcadorUpdateTimeConHoras) {
          console.log("Retornando datos desde la caché.");
          return {
            success: true,
            viaje: cachedData.viaje,
            paradas: cachedData.paradas,
          };
        } else {
          shouldFetchFromDb = true; // La caché está desactualizada
        }
      } else {
        shouldFetchFromDb = true; // No hay caché
      }
    } else {
      shouldFetchFromDb = true; // No se pudo obtener el marcador
    }

    if (shouldFetchFromDb) {
      // Consultar Firestore si la caché está desactualizada o no existe
      const viajeDocRef = doc(db, 'viajes', idDelViaje);
      const viajeDocSnapshot = await getDoc(viajeDocRef);

      if (!viajeDocSnapshot.exists()) {
        return {
          success: false,
          message: "No se encontró el viaje con el ID proporcionado",
        };
      }

      const viajeData = viajeDocSnapshot.data();

      const paradasQuerySnapshot = await getDocs(query(collection(db, 'paradas'), where('viaje_id', '==', idDelViaje)));

      const paradas = [];
      paradasQuerySnapshot.forEach((doc) => {
        paradas.push(doc.data());
      });

      // Guardar en caché los datos más recientes
      const newCache = {
        viaje: viajeData,
        paradas: paradas,
        lastUpdated: new Date().toISOString(),
      };
      cacheData(cacheKey, newCache);

      return {
        success: true,
        viaje: viajeData,
        paradas: paradas,
      };
    }
  } catch (error) {
    console.error('Error al obtener el viaje por ID:', error);
    return {
      success: false,
      message: 'Error al obtener el viaje por ID',
    };
  }
};

const getPasajeros = async (nombre, nro_celular, localidad) => {
  try {
    const pasajerosCollection = collection(db, 'pasajeros');
    let pasajerosQuery = query(pasajerosCollection);

    if (nombre) {
      pasajerosQuery = query(pasajerosCollection, where('nombre', '==', nombre));
    }

    if (nro_celular) {
      pasajerosQuery = query(pasajerosCollection, where('nro_celular', '==', nro_celular));
    }

    if (localidad) {
      pasajerosQuery = query(pasajerosCollection, where('localidad', '==', localidad));
    }

    const pasajerosSnapshot = await getDocs(pasajerosQuery);
    const pasajerosList = [];

    pasajerosSnapshot.forEach((doc) => {
      pasajerosList.push(doc.data());
    });

    return {
      success: true,
      pasajeros: pasajerosList,
    };
  } catch (error) {
    console.error('Error al obtener los pasajeros:', error);
    return {
      success: false,
      message: 'Error al obtener los pasajeros',
    };
  }
};

const reservarViaje = async (datosReserva, origen, destino, dataCupon = '') => {
  try {
    const { id_pasajero, id_viaje, cant_pasajeros, equipaje, comentario_adicional, origen_busqueda, destino_busqueda, precio_total } = datosReserva;

    const viajePasajeroCollection = collection(db, 'viaje_pasajero');
    const existingReservationQuery = query(
      viajePasajeroCollection,
      where('id_pasajero', '==', id_pasajero),
      where('id_viaje', '==', id_viaje)
    );
    const existingReservationSnapshot = await getDocs(existingReservationQuery);

    if (!existingReservationSnapshot.empty) {
      return { success: false, message: 'Ya has reservado este viaje.' };
    }

    const transactionResult = await runTransaction(db, async (transaction) => {
      const viajeDocRef = doc(db, 'viajes', id_viaje);
      const viajeDoc = await transaction.get(viajeDocRef);

      if (!viajeDoc.exists()) {
        throw new Error('El viaje no existe.');
      }

      const viajeData = viajeDoc.data();

      if (viajeData.asientos_disponibles < cant_pasajeros) {
        throw new Error('No hay suficientes asientos disponibles para realizar la reserva.');
      }

      const newReservation = {
        id_pasajero,
        id_viaje,
        origen_busqueda,
        origen,
        destino_busqueda,
        destino,
        precio_total,
        cant_pasajeros,
        equipaje,
        comentario_adicional
      };
      await transaction.set(doc(collection(db, 'viaje_pasajero')), newReservation);

      transaction.update(viajeDocRef, {
        asientos_disponibles: viajeData.asientos_disponibles - cant_pasajeros,
      });

      // Guardar información del cupón si dataCupon no es una cadena vacía
      if (dataCupon !== '') {
        const cuponPasajeroCollection = collection(db, 'cupones_pasajeros');
        const fechaUso = new Date();  // Fecha actual
        const newCuponUso = {
          id_cupon: dataCupon.id,
          id_pasajero: id_pasajero,
          fecha_uso: fechaUso
        };
        await transaction.set(doc(cuponPasajeroCollection), newCuponUso);
      }

      // Actualizar el marcador de viajes con la última fecha de actualización
      const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
      const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
      await setDoc(marcadorRef, {
        updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
      }, { merge: true });
    });

    return { success: true, message: 'Reserva realizada con éxito y marcador actualizado' };
  } catch (error) {
    return { success: false, message: error.message };
  }
};



const getPasajerosPorViaje = async (idViaje) => {
  try {
    const pasajerosCollection = collection(db, 'pasajeros');
    const viajePasajerosCollection = collection(db, 'viaje_pasajero');

    // Obtener los documentos de la colección viaje_pasajero con el id_viaje proporcionado
    const viajePasajerosQuery = query(viajePasajerosCollection, where('id_viaje', '==', idViaje));
    const viajePasajerosSnapshot = await getDocs(viajePasajerosQuery);
    const pasajeroIds = [];
    const viajePasajeroData = [];

    viajePasajerosSnapshot.forEach((doc) => {
      pasajeroIds.push(doc.data().id_pasajero);
      viajePasajeroData.push({ id: doc.id, ...doc.data() }); // Guardar los datos del documento de viaje_pasajero
    });

    // Obtener los documentos de la colección pasajeros con los ids de los pasajeros obtenidos
    const pasajerosQuery = query(pasajerosCollection, where('idInicio', 'in', pasajeroIds));
    const pasajerosSnapshot = await getDocs(pasajerosQuery);
    const pasajerosList = [];

    pasajerosSnapshot.forEach((doc) => {
      pasajerosList.push({ id: doc.id, ...doc.data() });
    });

    // Combinar los datos de pasajeros con los datos de viaje_pasajero
    const pasajerosConDatosDeViaje = pasajerosList.map(pasajero => {
      const viajePasajero = viajePasajeroData.find(vp => vp.id_pasajero === pasajero.idInicio);
      return {
        ...pasajero,
        viajePasajero,
      };
    });

    return {
      success: true,
      pasajeros: pasajerosConDatosDeViaje,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error al obtener los pasajeros por viaje',
      error: error.message, // Retornar el mensaje de error para una mejor depuración
    };
  }
};


const cancelarReservaViaje = async (datosReserva) => {
  try {
    const { id_pasajero, id_viaje } = datosReserva;

    console.log(datosReserva);

    const viajePasajeroCollection = collection(db, 'viaje_pasajero');
    const existingReservationQuery = query(
      viajePasajeroCollection,
      where('id_pasajero', '==', id_pasajero),
      where('id_viaje', '==', id_viaje)
    );
    const existingReservationSnapshot = await getDocs(existingReservationQuery);

    if (existingReservationSnapshot.empty) {
      return { success: false, message: 'No se encontró una reserva para cancelar.' };
    }

    const existingReservationDoc = existingReservationSnapshot.docs[0];
    const cant_pasajeros = existingReservationDoc.data().cant_pasajeros;

    const transactionResult = await runTransaction(db, async (transaction) => {
      // Eliminar la reserva
      transaction.delete(existingReservationDoc.ref);

      const viajeDocRef = doc(db, 'viajes', id_viaje);
      const viajeDoc = await transaction.get(viajeDocRef);

      if (!viajeDoc.exists()) {
        throw new Error('El viaje no existe.');
      }

      const viajeData = viajeDoc.data();

      // Actualizar la cantidad de asientos disponibles
      transaction.update(viajeDocRef, {
        asientos_disponibles: viajeData.asientos_disponibles + cant_pasajeros,
      });

      // Actualizar el marcador de viajes con la última fecha de actualización
      const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
      const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
      await setDoc(marcadorRef, {
        updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
      }, { merge: true });
    });

    return { success: true, message: 'Reserva cancelada exitosamente y marcador actualizado.' };
  } catch (error) {
    return { success: false, message: error.message };
  }
};


const getViajesPorUsuario1 = async (idUsuario) => {
  try {
    const viajesCollection = collection(db, 'viajes');
    const viajePasajeroCollection = collection(db, 'viaje_pasajero');

    const pasajeroQuery = query(viajePasajeroCollection, where('id_pasajero', '==', idUsuario));
    const pasajeroSnapshot = await getDocs(pasajeroQuery);

    const viajes = [];

    for (const doc of pasajeroSnapshot.docs) {
      const data = doc.data();
      const viajeId = data.id_viaje;
      const viajeDocRef = doc.ref;
      const viajeDocSnapshot = await getDoc(viajeDocRef);

      if (viajeDocSnapshot.exists()) {
        const viajeData = viajeDocSnapshot.data();
        viajeData.id = viajeId;
        viajes.push(viajeData);
      }
    }

    return {
      success: true,
      viajes,
    };
  } catch (error) {
    console.error('Error al obtener los viajes por usuario:', error);
    return {
      success: false,
      message: 'Error al obtener los viajes por usuario',
    };
  }
};

const getViajesPorUsuario = async (idUsuario) => {
  try {
    const viajePasajeroCollection = collection(db, 'viaje_pasajero');
    const viajesCollection = collection(db, 'viajes');

    // Obtener los IDs de los viajes del usuario
    const pasajeroQuery = query(viajePasajeroCollection, where('id_pasajero', '==', idUsuario));
    const pasajeroSnapshot = await getDocs(pasajeroQuery);

    if (pasajeroSnapshot.empty) {
      // Si no hay viajes asociados al usuario
      return {
        success: true,
        viajes: [],
      };
    }

    // Extraer todos los IDs de los viajes
    const viajeIds = pasajeroSnapshot.docs.map(doc => doc.data().id_viaje);

    // Crear una consulta para obtener solo los viajes asociados a los IDs obtenidos
    const viajesQuery = query(viajesCollection, where('id', 'in', viajeIds));
    const viajesSnapshot = await getDocs(viajesQuery);

    // Mapear los documentos a una lista de viajes
    const viajes = viajesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      success: true,
      viajes,
    };
  } catch (error) {
    console.error('Error al obtener los viajes por usuario:', error);
    return {
      success: false,
      message: 'Error al obtener los viajes por usuario',
    };
  }
};

const actualizarViaje = async (idViaje, nuevosDatos) => {
  try {
    if (!idViaje) {
      return { success: false, message: "Se requiere proporcionar un ID de viaje válido" };
    }

    const viajeRef = doc(db, 'viajes', idViaje);
    const viajeDoc = await getDoc(viajeRef);

    if (!viajeDoc.exists()) {
      return { success: false, message: "No se encontró un viaje con el ID proporcionado" };
    }

    const { origen, destino, hora, asientos_disponibles, fecha, precio, id_chofer } = nuevosDatos;

    const updateData = {
      origen, destino, hora, asientos_disponibles, fecha, precio, id_chofer
    };

    // Actualizar el viaje
    await updateDoc(viajeRef, updateData);

    // Actualizar el marcador de viajes con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    // Obtener los datos actualizados del viaje
    const updatedViaje = await getDoc(viajeRef);

    return {
      success: true,
      message: "Los datos del viaje se han actualizado exitosamente",
      updatedData: updatedViaje.data()
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const crearSolicitudViajeParticular = async (solicitudViaje) => {
  try {
    const { origen, destino, fecha, cant_pasajeros, direccion_busqueda, direccion_destino, id_solicitante, id_localidad } = solicitudViaje;

    if (!origen || !destino || !fecha || !cant_pasajeros || !direccion_busqueda || !direccion_destino || !id_solicitante || !id_localidad) {
      return { success: false, message: "Todos los campos deben estar completos: origen, destino, fecha, cant_pasajeros, direccion_busqueda, direccion_destino y id_solicitante" };
    }

    // Crear la solicitud de viaje particular
    const solicitudRef = await addDoc(collection(db, 'solicitudesViajeParticular'), {
      origen, destino, fecha, cant_pasajeros, direccion_busqueda, direccion_destino, id_solicitante, id_localidad
    });

    if (!solicitudRef.id) {
      return { success: false, message: "No se pudo crear la solicitud de viaje particular" };
    }

    // Actualizar el marcador de viajes particulares
    const marcadorRef = doc(db, 'marcadores', 'marcador_solicitud_particulares');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Solicitud de viaje particular creada exitosamente" };
  } catch (error) {
    return { success: false, message: error.message };
  }
};


const obtenerSolicitudesLimitadas = async (limite = 0) => {
  const cacheKey = `solicitudes_limitadas_${limite}`;
  const marcadorKey = 'marcador_solicitud_particulares'; // Clave para el marcador

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha de inicio para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        return {
          success: true,
          solicitudes: cachedData.solicitudes,
        };
      }
    }

    // Si la caché no es válida o no existe, obtener datos de la base de datos
    const solicitudesCollection = collection(db, 'solicitudesViajeParticular');
    const currentTimestamp = Timestamp.fromDate(new Date()); // Usar fecha y hora actuales

    const queryCollection = query(
      solicitudesCollection,
      where('fecha', '>=', currentTimestamp),
      orderBy('fecha', 'asc'),
      limit(limite)
    );

    const solicitudesSnapshot = await getDocs(queryCollection);
    const solicitudesList = [];

    solicitudesSnapshot.forEach((doc) => {
      solicitudesList.push({ id: doc.id, ...doc.data() });
    });

    // Almacenar los datos en caché
    const newCache = {
      solicitudes: solicitudesList,
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    return {
      success: true,
      solicitudes: solicitudesList,
    };
  } catch (error) {
    console.error('Error al obtener las solicitudes de viaje particular:', error);
    return {
      success: false,
      message: 'Error al obtener las solicitudes de viaje particular',
    };
  }
};

const obtenerSolicitudViajeParticularPorId = async (id) => {
  const cacheKey = `solicitud_viaje_particular_${id}`;
  const marcadorKey = 'marcador_solicitud_viaje_particular'; // Clave para el marcador

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha de inicio para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        return {
          success: true,
          solicitud: cachedData.solicitud,
        };
      }
    }

    // Si la caché no es válida o no existe, obtener datos de la base de datos
    const solicitudDocRef = doc(db, 'solicitudesViajeParticular', id);
    const solicitudDocSnapshot = await getDoc(solicitudDocRef);

    if (!solicitudDocSnapshot.exists()) {
      return {
        success: false,
        message: "No se encontró la solicitud de viaje particular con el ID proporcionado",
      };
    }

    const solicitudData = solicitudDocSnapshot.data();

    // Almacenar los datos en caché
    const newCache = {
      solicitud: { id: solicitudDocSnapshot.id, ...solicitudData },
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', marcadorKey);
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return {
      success: true,
      solicitud: { id: solicitudDocSnapshot.id, ...solicitudData },
    };
  } catch (error) {
    console.error('Error al obtener la solicitud de viaje particular:', error);
    return {
      success: false,
      message: 'Error al obtener la solicitud de viaje particular',
      error: error.message,
    };
  }
};


const marcarViajeTerminado = async (id) => {
  try {
    const viajeDocRef = doc(db, 'viajes', id);
    const viajeDoc = await getDoc(viajeDocRef);

    if (!viajeDoc.exists()) {
      return { success: false, message: 'El viaje con el ID proporcionado no existe' };
    }

    await updateDoc(viajeDocRef, {
      realizado: 'true'
    });

    // Actualizar el marcador de viajes particulares
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: 'Viaje marcado como terminado exitosamente' };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const eliminarViaje = async (idViaje) => {
  try {
    if (!idViaje) {
      return { success: false, message: "Se requiere proporcionar un ID de viaje válido" };
    }

    const viajeRef = doc(db, 'viajes', idViaje);
    const viajeDoc = await getDoc(viajeRef);

    if (!viajeDoc.exists()) {
      return { success: false, message: "No se encontró un viaje con el ID proporcionado" };
    }

    await deleteDoc(viajeRef);

    // Actualizar el marcador de viajes particulares
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "El viaje se ha eliminado exitosamente" };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const actualizarChofer = async (idChofer, nuevosDatos) => {
  try {
    if (!idChofer) {
      return { success: false, message: "Se requiere proporcionar un ID de chofer válido" };
    }

    const choferRef = doc(db, 'choferes', idChofer);
    const choferDoc = await getDoc(choferRef);

    if (!choferDoc.exists()) {
      return { success: false, message: "No se encontró un chofer con el ID proporcionado" };
    }

    const { nombre, correo, localidad, caract_cel, nro_cel, marca_vehiculo, modelo_vehiculo, patente_vehiculo, color_vehiculo } = nuevosDatos;

    const updateData = {
      nombre, correo, localidad, caract_cel, nro_cel, marca_vehiculo, modelo_vehiculo, patente_vehiculo, color_vehiculo
    };

    await updateDoc(choferRef, updateData);

    const updatedChofer = await getDoc(choferRef);
    return { success: true, message: "Los datos del chofer se han actualizado exitosamente", updatedData: updatedChofer.data() };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const agregarTarifa = async (datosTarifa) => {
  try {
    const { localidad, precio } = datosTarifa;

    if (!localidad || !precio) {
      return { success: false, error: "Todos los campos deben estar completos: localidad y precio" };
    }

    // Verificar si ya existe una tarifa para la localidad dada
    const tarifaExistenteQuery = query(collection(db, 'tarifas'), where('localidad', '==', localidad));
    const tarifaExistenteSnapshot = await getDocs(tarifaExistenteQuery);

    if (!tarifaExistenteSnapshot.empty) {
      return { success: false, error: "Ya existe una tarifa para esta localidad", error: 400 };
    }

    // Agregar una nueva tarifa sin el campo lastUpdated
    const docRef = await addDoc(collection(db, 'tarifas'), {
      localidad: localidad,
      precio: precio
    });

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_tarifas');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Tarifa agregada con éxito para la localidad " + localidad, id: docRef.id };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const eliminarTarifa = async (tarifaId) => {
  try {
    // Verificar si la tarifa existe
    const tarifaDocRef = doc(db, 'tarifas', tarifaId);
    const tarifaDocSnapshot = await getDoc(tarifaDocRef);

    if (!tarifaDocSnapshot.exists()) {
      return { success: false, error: "La tarifa no existe", error_code: 404 };
    }

    // Eliminar la tarifa
    await deleteDoc(tarifaDocRef);

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_tarifas');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Tarifa eliminada exitosamente", id: tarifaId };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getTarifas = async (limite = 0) => {
  const cacheKey = `tarifas_${limite}`;
  const marcadorKey = 'marcador_tarifas'; // Clave para el marcador

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha de inicio para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      // Comparar la fecha de la última actualización en la base de datos con la caché
      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        return {
          success: true,
          tarifas: cachedData.tarifas,
        };
      }
    }

    // Si la caché no es válida o no existe, obtener datos de la base de datos
    let tarifasQuery = collection(db, 'tarifas');

    if (limite && limite > 0) {
      tarifasQuery = query(tarifasQuery, limit(limite));
    }

    const tarifasSnapshot = await getDocs(tarifasQuery);
    const tarifas = [];

    tarifasSnapshot.forEach((doc) => {
      tarifas.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    // Almacenar los datos en caché
    const newCache = {
      tarifas: tarifas,
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    return {
      success: true,
      tarifas: tarifas,
    };
  } catch (error) {
    console.error('Error al obtener las tarifas:', error);
    return {
      success: false,
      error: error.message,
    };
  }
};


const getTarifaPorId = async (id) => {
  const cacheKey = `tarifa_${id}`;
  const marcadorKey = 'marcador_tarifas'; // Clave para el marcador

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha de inicio para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      // Comparar la fecha de la última actualización en la base de datos con la caché
      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        const tarifa = cachedData.tarifa.find(t => t.id === id);
        if (tarifa) {
          return { success: true, tarifa };
        }
      }
    }

    // Si la caché no es válida o no existe, obtener datos de la base de datos
    const tarifaDocRef = doc(db, 'tarifas', id);
    const tarifaSnapshot = await getDoc(tarifaDocRef);

    if (!tarifaSnapshot.exists()) {
      return { success: false, error: "No se encontró ninguna tarifa con el ID proporcionado" };
    }

    const tarifa = {
      id: tarifaSnapshot.id,
      ...tarifaSnapshot.data()
    };

    // Almacenar la tarifa en caché
    const newCache = {
      tarifa: [tarifa], // Almacenamos como una lista para mantener la coherencia con el formato de caché
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    return { success: true, tarifa };
  } catch (error) {
    console.error('Error al obtener la tarifa por ID:', error);
    return { success: false, error: error.message };
  }
};

const editarTarifa = async (idTarifa, tarifa) => {
  try {
    console.log(idTarifa);
    console.log(tarifa);

    const { localidad, precio } = tarifa;

    if (!localidad || !precio) {
      return { success: false, error: "Todos los campos deben estar completos: localidad y precio" };
    }

    const tarifaDocRef = doc(db, 'tarifas', idTarifa);
    const tarifaSnapshot = await getDoc(tarifaDocRef);

    if (!tarifaSnapshot.exists()) {
      return { success: false, error: "No se encontró ninguna tarifa con el ID proporcionado" };
    }

    // Actualizar el documento con nuevos datos y lastUpdated
    await updateDoc(tarifaDocRef, {
      localidad: localidad,
      precio: precio
    });

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_tarifas');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Tarifa actualizada con éxito" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};


const crearViajeParticularYEliminarSolicitud = async (datosViaje, idSolicitud) => {
  try {
    // Extraer los datos del objeto datosViaje
    const { destino, direccion_busqueda, direccion_destino, fecha, id_solicitador, origen, pasajeros, precio, id_chofer, localidad } = datosViaje;

    // Verificar si se proporcionaron todos los datos necesarios
    if (!destino || !direccion_busqueda || !direccion_destino || !fecha || !id_solicitador || !origen || !pasajeros || !idSolicitud || !precio || !id_chofer || !localidad) {
      return { success: false, error: "Todos los campos deben estar completos: destino, direccion_busqueda, direccion_destino, fecha, id_solicitador, origen, pasajeros, idSolicitud, precio y hora" };
    }

    // Crear el viaje particular
    const docRefViajeParticular = await addDoc(collection(db, 'viajes_particulares'), {
      destino: destino,
      direccion_busqueda: direccion_busqueda,
      direccion_destino: direccion_destino,
      fecha: fecha,
      id_solicitador: id_solicitador,
      origen: origen,
      pasajeros: pasajeros,
      realizado: 'false',
      precio: precio,
      id_chofer: id_chofer,
      localidad: localidad
    });

    if (!docRefViajeParticular.id) {
      return { success: false, error: "No se pudo crear el viaje particular" };
    }

    // Eliminar la solicitud de viaje particular asociada
    await deleteDoc(doc(db, 'solicitudesViajeParticular', idSolicitud));

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes_particulares');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRefParticular = doc(db, 'marcadores', 'marcador_solicitud_particulares');
    await setDoc(marcadorRefParticular, {
      updateTime: fechaHoraActual // Agregar la fecha y hora actual
    }, { merge: true });

    return { success: true, message: "Viaje particular creado exitosamente y solicitud de viaje particular eliminada" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};



const eliminarSolicitudViajeParticular = async (solicitudId) => {
  try {
    if (!solicitudId) {
      return { success: false, error: "Se requiere un ID de solicitud de viaje particular para eliminar" };
    }

    await deleteDoc(doc(db, 'solicitudesViajeParticular', solicitudId));

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRefParticular = doc(db, 'marcadores', 'marcador_solicitudes_particulares');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRefParticular, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Solicitud de viaje particular eliminada exitosamente" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const obtenerViajesParticulares = async (origen, destino, realizados) => {
  const cacheKey = `viajes_particulares_${origen}_${destino}_${realizados}`;
  const marcadorKey = 'marcador_viajes_particulares'; // Clave para el marcador

  try {
    // Consultar el marcador para obtener la fecha de la última actualización
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha de inicio para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      // Comparar la fecha de la última actualización en la base de datos con la caché
      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        return {
          success: true,
          viajesParticulares: cachedData.viajesParticulares,
        };
      }
    }

    // Construir la consulta según los filtros
    let queryRef = collection(db, 'viajes_particulares');

    if (origen !== '0' && destino !== '0') {
      queryRef = query(queryRef, where('origen', '==', origen), where('destino', '==', destino));
    } else if (origen !== '0') {
      queryRef = query(queryRef, where('origen', '==', origen));
    } else if (destino !== '0') {
      queryRef = query(queryRef, where('destino', '==', destino));
    }

    if (realizados !== '0') {
      if (realizados === '1') {
        queryRef = query(queryRef, where('realizado', '==', true));
      } else if (realizados === '2') {
        queryRef = query(queryRef, where('realizado', '==', false));
      }
    }

    // Obtener datos de la base de datos
    const viajesParticularesSnapshot = await getDocs(queryRef);
    const viajesParticulares = [];

    viajesParticularesSnapshot.forEach((doc) => {
      viajesParticulares.push({ id: doc.id, ...doc.data() });
    });

    // Almacenar los datos en caché
    const newCache = {
      viajesParticulares: viajesParticulares,
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    return {
      success: true,
      viajesParticulares,
    };
  } catch (error) {
    console.error('Error al obtener los viajes particulares:', error);
    return {
      success: false,
      message: 'Error al obtener los viajes particulares',
    };
  }
};

const obtenerViajeParticularPorId = async (id) => {
  const cacheKey = `viaje_particular_${id}`;
  const marcadorKey = 'marcador_viajes_particulares'; // Clave para el marcador

  try {
    // Consultar el marcador para obtener la fecha de la última actualización
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha de inicio para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      // Comparar la fecha de la última actualización en la base de datos con la caché
      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        return {
          success: true,
          viajeParticular: cachedData.viajeParticular,
        };
      }
    }

    // Obtener el documento de la base de datos
    const viajeDocRef = doc(db, 'viajes_particulares', id);
    const viajeDoc = await getDoc(viajeDocRef);

    if (viajeDoc.exists()) {
      const viajeParticular = { id: viajeDoc.id, ...viajeDoc.data() };

      // Almacenar los datos en caché
      const newCache = {
        viajeParticular: viajeParticular,
        lastUpdated: new Date().toISOString(),
      };
      cacheData(cacheKey, newCache);

      return {
        success: true,
        viajeParticular: viajeParticular,
      };
    } else {
      return {
        success: false,
        message: 'Viaje no encontrado',
      };
    }
  } catch (error) {
    console.error('Error al obtener el viaje particular:', error);
    return {
      success: false,
      message: 'Error al obtener el viaje particular',
    };
  }
};

const obtenerPasajeroPorId = async (idInicio) => {
  try {
    const pasajerosCollection = collection(db, 'pasajeros');
    const pasajerosQuery = query(pasajerosCollection, where('idInicio', '==', idInicio));
    const pasajerosSnapshot = await getDocs(pasajerosQuery);

    if (!pasajerosSnapshot.empty) {
      // Si se encontraron resultados, asumiremos que hay solo un pasajero con el ID de inicio único
      const pasajeroDoc = pasajerosSnapshot.docs[0];
      const pasajeroData = pasajeroDoc.data();
      
      // Agrega el ID del pasajero a los datos del pasajero
      pasajeroData.id = pasajeroDoc.id;

      return {
        success: true,
        pasajero: pasajeroData,
      };
    } else {
      return {
        success: false,
        message: 'Pasajero no encontrado',
      };
    }
  } catch (error) {
    console.error('Error al obtener el pasajero por ID de inicio:', error);
    return {
      success: false,
      message: 'Error al obtener el pasajero por ID de inicio',
    };
  }
};

const eliminarViajeParticular = async (idViajeParticular) => {
  try {
    if (!idViajeParticular) {
      return { success: false, message: "Se requiere proporcionar un ID de viaje particular válido" };
    }

    const viajeParticularRef = doc(db, 'viajes_particulares', idViajeParticular);
    const viajeParticularDoc = await getDoc(viajeParticularRef);

    if (!viajeParticularDoc.exists()) {
      return { success: false, message: "No se encontró un viaje particular con el ID proporcionado" };
    }

    await deleteDoc(viajeParticularRef);

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes_particulares');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "El viaje particular se ha eliminado exitosamente" };
  } catch (error) {
    console.error('Error al eliminar el viaje particular:', error);
    return { success: false, message: "Error al eliminar el viaje particular" };
  }
};

const marcarViajeParticularTerminado = async (idViajeParticular) => {
  try {
    const viajeParticularDocRef = doc(db, 'viajes_particulares', idViajeParticular);
    const viajeParticularDoc = await getDoc(viajeParticularDocRef);

    if (!viajeParticularDoc.exists()) {
      return { success: false, message: 'El viaje particular con el ID proporcionado no existe' };
    }

    await updateDoc(viajeParticularDocRef, {
      realizado: 'true'
    });

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_viajes_particulares');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: 'Viaje particular marcado como terminado exitosamente' };
  } catch (error) {
    console.error('Error al marcar el viaje particular como terminado:', error);
    return { success: false, message: error.message };
  }
};

const getViajesParticularesPorUsuario = async (idUsuario) => {
  try {
    const viajesParticularesCollection = collection(db, 'viajes_particulares');
    const solicitadorQuery = query(
      viajesParticularesCollection,
      where('id_solicitador', '==', idUsuario)
    );

    const solicitadorSnapshot = await getDocs(solicitadorQuery);

    // Usa map para transformar los documentos en un array de datos
    const viajesParticulares = solicitadorSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return {
      success: true,
      viajesParticulares,
    };
  } catch (error) {
    console.error('Error al obtener los viajes particulares por usuario:', error);
    return {
      success: false,
      message: 'Error al obtener los viajes particulares por usuario',
    };
  }
};


const agregarPublicidad = async (file, empresa) => {
  try {
    if (!file || !empresa) {
      return { success: false, error: "Todos los campos deben estar completos: empresa y archivo de imagen" };
    }

    const storage = getStorage();
    const storageRef = ref(storage, `publicidad/${file.name}`);

    // Subir archivo a Firebase Storage
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);

    // Guardar los datos en Firestore
    const docRef = await addDoc(collection(db, 'publicidad'), {
      empresa: empresa,
      url: imageUrl
    });

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_publicidad');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Publicidad agregada con éxito para la empresa " + empresa, id: docRef.id };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const obtenerPublicidades = async () => {
  try {
    // Función para obtener la fecha del marcador
    const obtenerFechaMarcador = async () => {
      const marcadorRef = doc(db, 'marcadores', 'marcador_publicidad');
      const marcadorDoc = await getDoc(marcadorRef);

      if (marcadorDoc.exists()) {
        const data = marcadorDoc.data();
        return new Date(data.updateTime);
      } else {
        return null;
      }
    };

    // Obtener la fecha del marcador y la fecha de la caché
    const fechaMarcador = await obtenerFechaMarcador();
    const fechaCache = localStorage.getItem('fechaCachePublicidades');
    const publicidadesCache = localStorage.getItem('publicidades');

    // Si no hay fecha de marcador o caché, o la caché está desactualizada
    if (!fechaMarcador || !publicidadesCache || new Date(fechaCache) < fechaMarcador) {
      // Consultar la base de datos
      const publicidadesCollection = collection(db, 'publicidad');
      const publicidadesSnapshot = await getDocs(publicidadesCollection);

      const publicidades = publicidadesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Guardar datos en caché
      localStorage.setItem('fechaCachePublicidades', new Date().toISOString());
      localStorage.setItem('publicidades', JSON.stringify(publicidades));

      return { success: true, publicidades: publicidades };
    } else {
      // Usar datos de caché
      return { success: true, publicidades: JSON.parse(publicidadesCache) };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};


const actualizarPublicidad = async (id, nuevosDatos, newImageFile) => {
  try {
    if (!id || !nuevosDatos) {
      return { success: false, error: "ID y datos de actualización son requeridos" };
    }

    let updatedData = { ...nuevosDatos };

    if (newImageFile) {
      const storage = getStorage();
      const storageRef = ref(storage, `publicidad/${newImageFile.name}`);

      // Subir el nuevo archivo a Firebase Storage
      await uploadBytes(storageRef, newImageFile);
      const newImageUrl = await getDownloadURL(storageRef);

      updatedData.url = newImageUrl; // Actualiza la URL de la imagen en los nuevos datos
    }

    const publicidadDocRef = doc(db, 'publicidad', id);
    await updateDoc(publicidadDocRef, updatedData);

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_publicidad');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Publicidad actualizada con éxito" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};


const obtenerPublicidadPorId = async (id) => {
  try {
    if (!id) {
      return { success: false, error: "ID es requerido" };
    }

    const publicidadDocRef = doc(db, 'publicidad', id);
    const publicidadDoc = await getDoc(publicidadDocRef);

    if (publicidadDoc.exists()) {
      return { success: true, publicidad: { id: publicidadDoc.id, ...publicidadDoc.data() } };
    } else {
      return { success: false, error: "Publicidad no encontrada" };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const obtenerImagenDesdeStorage = async (url) => {
  try {
    const storage = getStorage();
      const imageRef = ref(storage, url);
      const imageUrl = await getDownloadURL(imageRef);
      return imageUrl;
  } catch (error) {
      console.error('Error al obtener la imagen desde storage:', error);
      throw new Error('Error al obtener la imagen desde storage');
  }
};

const crearCuponDescuento = async (formData, fecha) => {
  try {
    const { codigo, tipo, duracion, dias, monto, porcentaje } = formData;

    let fecha_inicio = '';
    let fecha_fin = '';

    if (fecha && fecha.length === 2) {
      fecha_inicio = fecha[0];
      fecha_fin = fecha[1];
    }

    const docRef = await addDoc(collection(db, 'cupones_descuento'), {
      codigo: codigo,
      tipo: tipo,
      duracion: duracion,
      dias: dias,
      monto: monto,
      porcentaje: porcentaje,
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin
    });

    return { success: true, message: "Cupón de descuento creado con éxito", id: docRef.id };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const obtenerCuponesDescuento = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'cupones_descuento'));
    const cupones = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    return { success: true, cupones: cupones };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const obtenerCuponPorId = async (cuponId) => {
  try {
    const docRef = doc(db, 'cupones_descuento', cuponId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { success: true, cupon: { id: docSnap.id, ...docSnap.data() } };
    } else {
      return { success: false, message: "No se encontró el cupón con el ID proporcionado." };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};



const editarCuponDescuento = async (cuponId, formData, fecha) => {
  try {
    const { codigo, tipo, duracion, fechas, dias, monto, porcentaje } = formData;
    const docRef = doc(db, 'cupones_descuento', cuponId);

    let fecha_inicio = '';
    let fecha_fin = '';

    if (fecha && fecha.length === 2) {
      fecha_inicio = fecha[0];
      fecha_fin = fecha[1];
    }

    await updateDoc(docRef, {
      codigo: codigo,
      tipo: tipo,
      duracion: duracion,
      dias: dias,
      monto: monto,
      porcentaje: porcentaje,
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin
    });

    return { success: true, message: "Cupón de descuento actualizado con éxito" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const eliminarCuponDescuento = async (cuponId) => {
  try {
    const docRef = doc(db, 'cupones_descuento', cuponId);
    await deleteDoc(docRef);
    
    return { success: true, message: "Cupón de descuento eliminado con éxito" };
  } catch (error) {
    return { success: false, error: error.message };
  }
};


const obtenerCuponPorCodigo = async (codigo) => {
  try {
    const cuponesRef = collection(db, 'cupones_descuento');
    const q = query(cuponesRef, where('codigo', '==', codigo));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docSnap = querySnapshot.docs[0];
      return { success: true, cupon: { id: docSnap.id, ...docSnap.data() } };
    } else {
      return { success: false, message: "No se encontró el cupón con el código proporcionado." };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getViajesPorCiudad = async (ciudad, limite = 10) => {
  try {
    // Función para obtener la fecha del marcador
    const obtenerFechaMarcador = async () => {
      const marcadorRef = doc(db, 'marcadores', 'marcador_viajes');
      const marcadorDoc = await getDoc(marcadorRef);

      if (marcadorDoc.exists()) {
        const data = marcadorDoc.data();
        return new Date(data.updateTime);
      } else {
        return null;
      }
    };

    // Obtener la fecha del marcador y la fecha de la caché
    const fechaMarcador = await obtenerFechaMarcador();
    const fechaCache = localStorage.getItem(`fechaCacheViajes_${ciudad}`);
    const viajesCache = localStorage.getItem(`viajes_${ciudad}`);

    // Si no hay fecha de marcador o caché, o la caché está desactualizada
    if (!fechaMarcador || !viajesCache || new Date(fechaCache) < fechaMarcador) {
      // Consultar la base de datos
      const paradasCollection = collection(db, 'paradas');
      const paradaQuery = query(paradasCollection, where('ciudad', '==', ciudad), limit(limite));

      const paradaSnapshot = await getDocs(paradaQuery);

      const viajeIds = [];

      paradaSnapshot.forEach((doc) => {
        const data = doc.data();
        viajeIds.push(data.viaje_id);
      });

      if (viajeIds.length === 0) {
        // Actualizar caché con datos vacíos
        localStorage.setItem(`fechaCacheViajes_${ciudad}`, new Date().toISOString());
        localStorage.setItem(`viajes_${ciudad}`, JSON.stringify([]));

        return {
          success: true,
          viajes: [],
        };
      }

      const viajesCollection = collection(db, 'viajes');
      const viajeDocsPromises = viajeIds.map((id) => getDoc(doc(viajesCollection, id)));
      const viajeDocsSnapshots = await Promise.all(viajeDocsPromises);

      const viajes = [];
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();
      const formattedCurrentDate = `${day}/${month}/${year}`;

      viajeDocsSnapshots.forEach((viajeDocSnapshot) => {
        if (viajeDocSnapshot.exists()) {
          const viajeData = viajeDocSnapshot.data();
          const viajeFecha = viajeData.fecha;
          const realizado = viajeData.realizado;

          if (viajeFecha >= formattedCurrentDate && realizado === "false") {
            viajeData.id = viajeDocSnapshot.id;
            viajes.push(viajeData);
          }
        }
      });

      // Actualizar caché con los nuevos datos
      localStorage.setItem(`fechaCacheViajes_${ciudad}`, new Date().toISOString());
      localStorage.setItem(`viajes_${ciudad}`, JSON.stringify(viajes));

      return {
        success: true,
        viajes,
      };
    } else {
      // Usar datos de caché
      return {
        success: true,
        viajes: JSON.parse(viajesCache),
      };
    }
  } catch (error) {
    console.error('Error al obtener los viajes por ciudad:', error);
    return {
      success: false,
      message: 'Error al obtener los viajes por ciudad',
    };
  }
};

const actualizarFechasAViajes = async () => {
  const viajesRef = collection(db, 'viajes');

  try {
    // Obtén todos los documentos en la colección 'viajes'
    const querySnapshot = await getDocs(viajesRef);
    
    // Recorre todos los documentos
    querySnapshot.forEach(async (document) => {
      const data = document.data();
      const fecha = data.fecha; // Obtén el valor del campo 'fecha'

      // Verifica si 'fecha' es una cadena
      if (typeof fecha === 'string') {
        // Convierte la cadena de fecha a un objeto Date
        const [day, month, year] = fecha.split('/');
        const fechaDate = new Date(year, month - 1, day); // Date en JS usa índices de mes basados en 0

        // Normaliza la fecha a medianoche
        fechaDate.setHours(0, 0, 0, 0);

        // Convierte la fecha a Timestamp
        const fechaTimestamp = Timestamp.fromDate(fechaDate);

        // Actualiza el campo 'fecha' en Firestore con el Timestamp
        const docRef = doc(db, 'viajes', document.id);
        await updateDoc(docRef, {
          fecha: fechaTimestamp,
        });

        console.log(`Documento ${document.id} actualizado con la fecha ${fechaTimestamp.toDate()}`);
      }
    });
  } catch (error) {
    console.error('Error al actualizar las fechas:', error);
  }
};

const getParadaPorId = async (id) => {
  const cacheKey = `parada_${id}`;
  const marcadorKey = 'marcador_paradas'; // Clave para el marcador de actualizaciones de paradas

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha inicial para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Verificar caché
    const cachedData = getCachedData(cacheKey);
    if (cachedData && cachedData.lastUpdated) {
      const cachedLastUpdate = new Date(cachedData.lastUpdated);

      // Comparar la fecha de la última actualización en la base de datos con la caché
      if (cachedLastUpdate >= lastUpdate) {
        console.log("Retornando datos desde la caché.");
        const parada = cachedData.parada.find(p => p.id === id);
        if (parada) {
          return { success: true, parada };
        }
      }
    }

    // Si la caché no es válida o no existe, obtener datos de la base de datos
    const paradaDocRef = doc(db, 'paradas', id);
    const paradaSnapshot = await getDoc(paradaDocRef);

    if (!paradaSnapshot.exists()) {
      return { success: false, error: "No se encontró ninguna parada con el ID proporcionado" };
    }

    const parada = {
      id: paradaSnapshot.id,
      ...paradaSnapshot.data()
    };

    // Almacenar la parada en caché
    const newCache = {
      parada: [parada], // Almacenamos como una lista para mantener la coherencia con el formato de caché
      lastUpdated: new Date().toISOString(),
    };
    cacheData(cacheKey, newCache);

    return { success: true, parada };
  } catch (error) {
    console.error('Error al obtener la parada por ID:', error);
    return { success: false, error: error.message };
  }
};

const updatePrecioParada = async (idViaje, ciudad, nuevoPrecio) => {

  try {
    // Crear la consulta para obtener el documento basado en viaje_id y ciudad
    const paradaQuery = query(
      collection(db, 'paradas'),
      where('viaje_id', '==', idViaje),
      where('ciudad', '==', ciudad)
    );

    const paradaSnapshot = await getDocs(paradaQuery);

    // Verificar si se encontró alguna parada
    if (paradaSnapshot.empty) {
      return { success: false, error: "No se encontró ninguna parada con los criterios proporcionados" };
    }

    // Asumimos que solo habrá una parada que coincida con viaje_id y ciudad
    const paradaDoc = paradaSnapshot.docs[0].ref;

    // Actualizar solo el precio de la parada
    await updateDoc(paradaDoc, {
      precio: nuevoPrecio
    });

    // Actualizar el marcador con la última fecha de actualización
    const marcadorRef = doc(db, 'marcadores', 'marcador_paradas');
    const fechaHoraActual = obtenerFechaHoraAjustada(-3, 6);
    await setDoc(marcadorRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    const marcadorViajeRef = doc(db, 'marcadores', 'marcador_viajes');
    await setDoc(marcadorViajeRef, {
      updateTime: fechaHoraActual // Agregar la fecha y hora ajustada
    }, { merge: true });

    return { success: true, message: "Precio actualizado correctamente." };
  } catch (error) {
    console.error('Error al actualizar el precio de la parada:', error);
    return { success: false, error: error.message };
  }
};

  

const getParadaPorViajeYCiudad = async (viajeId, ciudad) => {
  const marcadorKey = 'marcador_paradas'; // Clave para el marcador de actualizaciones de paradas

  try {
    // Consultar el marcador
    const marcadorSnapshot = await getDoc(doc(db, 'marcadores', marcadorKey));
    let lastUpdate = new Date(0); // Fecha inicial para comparación

    if (marcadorSnapshot.exists()) {
      const marcadorData = marcadorSnapshot.data();
      lastUpdate = marcadorData.updateTime ? new Date(marcadorData.updateTime) : new Date(0);
    }

    // Hacer consulta a Firestore con los filtros viajeId y ciudad
    const paradaQuery = query(
      collection(db, 'paradas'),
      where('viaje_id', '==', viajeId),
      where('ciudad', '==', ciudad)
    );

    const paradaSnapshot = await getDocs(paradaQuery);

    if (paradaSnapshot.empty) {
      return { success: false, error: "No se encontraron paradas con los criterios proporcionados." };
    }

    const paradas = paradaSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return { success: true, paradas };
  } catch (error) {
    console.error('Error al obtener la parada por viaje_id y ciudad:', error);
    return { success: false, error: error.message };
  }
};


export {
  registrarUsuario,
  iniciarSesion,
  cerrarSesion,
  loginAdmin, 
  registrarChofer, 
  getChoferes, 
  crearViaje,
  getViajePorId, 
  getChoferPorViaje, 
  reservarViaje,
  getPasajerosPorViaje,
  cancelarReservaViaje,
  getViajesPorUsuario,
  actualizarViaje,
  obtenerSolicitudesLimitadas,
  crearSolicitudViajeParticular,
  obtenerSolicitudViajeParticularPorId,
  marcarViajeTerminado,
  eliminarViaje,
  getChoferPorId,
  actualizarChofer,
  agregarTarifa,
  getTarifas,
  getTarifaPorId,
  editarTarifa,
  eliminarTarifa,
  crearViajeParticularYEliminarSolicitud,
  eliminarSolicitudViajeParticular,
  obtenerViajesParticulares,
  obtenerViajeParticularPorId,
  obtenerPasajeroPorId,
  eliminarViajeParticular,
  marcarViajeParticularTerminado,
  getViajesParticularesPorUsuario,
  agregarPublicidad,
  obtenerPublicidades,
  actualizarPublicidad,
  obtenerPublicidadPorId,
  obtenerImagenDesdeStorage,
  crearCuponDescuento,
  obtenerCuponesDescuento,
  obtenerCuponPorId,
  editarCuponDescuento,
  eliminarCuponDescuento,
  obtenerCuponPorCodigo,
  getViajesPorCiudad,
  obtenerUltimosViajes,
  obtenerViajesOrigenDestino,
  actualizarFechasAViajes,
  getParadaPorId,
  updatePrecioParada,
  getParadaPorViajeYCiudad
};
