import { useRef, useEffect } from "react";
import { Input, Box, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa"; // Icono de calendario
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/dark.css";
import { Spanish } from "flatpickr/dist/l10n/es.js"; // Importa el archivo de idioma

// eslint-disable-next-line react/prop-types
const DatePicker = ({ onDateChange }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const fp = flatpickr(inputRef.current, {
            mode: "multiple", // Permitir selección de múltiples fechas
            dateFormat: "Y-m-d", // Formato de la fecha
            time_24hr: true, // Usar el formato de 24 horas
            locale: Spanish, // Usar el idioma español
            minDate: "today",
            disableMobile: true, // Deshabilitar la interfaz de móvil
            onChange: (selectedDates) => {
                onDateChange(selectedDates); // Pasar todas las fechas seleccionadas
            },
        });

        // Cleanup para destruir el calendario cuando el componente se desmonte
        return () => fp.destroy();
    }, [onDateChange]);

    return (
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents="none">
                    <FaCalendarAlt color="#1e1e1e" />
                </InputLeftElement>
                <Input
                    w={"100%"}
                    ref={inputRef}
                    placeholder="Fecha"
                    color="#1e1e1e"
                />
            </InputGroup>
        </Box>
    );
};

export default DatePicker;
