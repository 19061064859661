import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi';
import { MdOutlineCancel } from 'react-icons/md';
import EditarViaje from './EditarViaje';
import { useNavigate } from 'react-router-dom';
import { obtenerUltimosViajes } from '../../database';

const TablaViajes = () => {

  const [viajes, setViajes] = useState();
  const navigate = useNavigate();

  const obtenerViajesFunc = async () => {
    const resultadoViajes = await obtenerUltimosViajes(7);

    console.log(resultadoViajes)

    if (resultadoViajes.success) {
      setViajes(resultadoViajes.viajes);
    }
  }

  const formatearFecha = (fechaISO) => {
    const fecha = new Date(fechaISO);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear().toString().substring(2);
    return `${dia}/${mes}/${anio}`;
  }
  const obtenerElementoEnPosicion1 = (texto) => {
    const arreglo = texto.split(',');
    if (arreglo.length >= 2) {
      return arreglo[1].trim();
    } else {
      return null;
    }
  };

  useEffect(() => {
    obtenerViajesFunc();
  }, [])

  const [idViaje, setIdViaje] = useState('');



  const toast = useToast();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const obtenerViajeEditar = (id) => {
    setIdViaje(id);
    onOpen();
  }

  const convertirFecha = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000); // Convertir segundos a milisegundos
      return date.toLocaleDateString(); // O usa toLocaleString() si necesitas la hora también
    }
    return '';
  };

  return (
    <>
      <TableContainer w="100%">
        <Table size='sm' colorScheme='primary'>
          <TableCaption bg="red.100" w="100%" h="30px" p="0" mt="0px">
            <Text cursor="pointer" mt="4px" textDecoration="underline" onClick={() => { navigate('/viajes-creados') }}>Ver todos</Text>
          </TableCaption>
          <Thead>
            <Tr h="40px">
              <Th w="35%" p="0">Salida</Th>
              <Th w="35%" p="0">Destino</Th>
              <Th w="20%" textAlign="center" p="0">Fecha</Th>
              <Th w="20%" textAlign="center" p="0">Hora</Th>
            </Tr>
          </Thead>
          <Tbody>
            {viajes && viajes.map((data, index) => (
              <Tr key={index} h="40px" cursor="pointer" onClick={() => { obtenerViajeEditar(data.id) }}>
                <Td p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" onClick={() => { navigate(`/viaje/${data.id}`) }}>
                  {obtenerElementoEnPosicion1(data.origen)}
                </Td>
                <Td p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" onClick={() => { navigate(`/viaje/${data.id}`) }}>
                  {obtenerElementoEnPosicion1(data.destino)}
                </Td>
                <Td textAlign="center" p="0" onClick={() => { navigate(`/viaje/${data.id_}`) }}>{convertirFecha(data.fecha)}</Td>
                <Td p="0" display="flex" flexDirection="row" h="40px" justifyContent="flex-end" alignItems="center" gap="5px">{data.hora}</Td>
              </Tr>
            ))}

          </Tbody>
        </Table>
      </TableContainer>
      {!isLargerThan800 &&
        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
              <Text>Datos del viaje</Text>
            </DrawerHeader>
            <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
              <EditarViaje id={idViaje} cerrarPopup={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>}
      {isLargerThan800 &&
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w={["380px", "100%", "100%"]}>
            <ModalHeader>Datos del viaje</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <EditarViaje id={idViaje} cerrarPopup={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>}
    </>
  )
}

export default TablaViajes